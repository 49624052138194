import { render, staticRenderFns } from "./register.vue?vue&type=template&id=bee0c686&scoped=true&"
import script from "./register.vue?vue&type=script&lang=js&"
export * from "./register.vue?vue&type=script&lang=js&"
import style0 from "./register.vue?vue&type=style&index=0&id=bee0c686&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bee0c686",
  null
  
)

export default component.exports